import React from "react";
import {Segment, Grid, List, Container, Header, Icon, ListHeader} from "semantic-ui-react"



const Footer2 = () => {
    return (
        <Segment inverted vertical style={{ padding: '5em 0em 2.5em', backgroundColor: "#131927", color: "#A3A5AE" }} >
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={5}>
              <Header inverted as='h2' content='Our Social Networks' style={{marginBottom: "30px"}} />
              <List  link inverted relaxed verticalAlign="middle">

                        <List.Item as='a' href="https://twitter.com/DollarHorizon?s=09" style={{marginBottom: "25px"}}>
                            <List.Icon name="twitter" size="big" color="grey"/>
                            <List.Content>
                                <ListHeader style={{fontSize: "18px", marginLeft: "12px"}}>Twitter</ListHeader>
                                <List.Description style={{marginTop: "15px", marginLeft: "12px"}}>
                                    Horizon Dollar Twitter <Icon size="large"  name="angle right" color="grey"/>
                                 </List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item as='a' href="https://t.me/HorizonDollar1" style={{marginBottom: "25px"}}>
                            <List.Icon name="telegram" size="big" color="grey" />
                            <List.Content>
                                <ListHeader style={{fontSize: "18px", marginLeft: "12px"}}>Telegram</ListHeader>
                                <List.Description style={{marginTop: "15px", marginLeft: "12px"}}>
                                    Horizon Dollar Telegram <Icon size="large"  name="angle right" color="grey"/>
                                 </List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item as='a' href="https://www.facebook.com/HorizonDollar/" style={{marginBottom: "25px"}}>
                            <List.Icon name="facebook" size="big" color="grey" />
                            <List.Content>
                                <ListHeader style={{fontSize: "18px", marginLeft: "12px"}}>Facebook</ListHeader>
                                <List.Description style={{marginTop: "15px", marginLeft: "12px"}}>
                                    Horizon Dollar Facebook <Icon size="large"  name="angle right" color="grey"/>
                                 </List.Description>
                            </List.Content>
                        </List.Item>

                        <List.Item as='a' href="https://www.instagram.com/horizondollar/" style={{marginBottom: "25px"}}>
                            <List.Icon name="instagram" size="big" color="grey" />
                            <List.Content>
                            <ListHeader style={{fontSize: "18px", marginLeft: "12px"}}>Instagram</ListHeader>
                            <List.Description style={{marginTop: "15px", marginLeft: "12px"}}>
                                Horizon Dollar Instagram <Icon size="large"  name="angle right" color="grey"/>
                             </List.Description>
                        </List.Content>
                        </List.Item>
                    </List>
            </Grid.Column>
            <Grid.Column width={5}  verticalAlign="middle">
              <Header inverted as='h2' content='About' />
              <List link inverted size="huge" verticalAlign="middle">
                <List.Item as='a' href="/#home">Home</List.Item>
                <List.Item as='a' href="/#tokenomics">Tokenomics</List.Item>
                <List.Item as='a' href="/#howitworks">How it Works</List.Item>
                <List.Item as='a' href="/#roadmap">Roadmap</List.Item>
                <List.Item as='a' href="/tradetoken">Trade token</List.Item>
                <List.Item as='a' href="/#downloadlogo">Download our logos</List.Item>
                <List.Item as='a' href='mailto: info@hzdfoundation.org'>Contact</List.Item>
              </List>
            </Grid.Column>

            <Grid.Column width={6}  verticalAlign="middle">
                    <p style={{ wordSpacing: "0.3em", fontWeight: "bold", fontSize: "16px"}}>Copyright&#169; 2021 Horizon Dollar | HZD | hzdfoundation. All rights reserved</p>
            </Grid.Column>
            </Grid.Row>
        </Grid>
      </Container>
    </Segment>
    );

}

export default Footer2;


import React, { useState } from 'react';
import { Segment, Grid, Image, Button, Icon } from "semantic-ui-react";
import coingecko_logo from "../assets/images/coingecko.png";
import bscscan_logo from "../assets/images/bscscan.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "../App.css";

const Partners = () => {

    const [copyPlaceholderText, setcopyPlaceholderText] = useState("Copy Contract Address");

    const HandleOnCopy = () => {
        setcopyPlaceholderText("copied!");
        setTimeout(() => setcopyPlaceholderText("Copy Contract Address"), 2000)
    };

    return (
        <Segment basic class="partners">
            <Grid centered verticalAlign="middle" stackable columns={3}>
                <Grid.Column width={4}>
                    <Image as="a" href="https://bscscan.com/token/0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347" size="small" src={bscscan_logo} />
                </Grid.Column>
                <Grid.Column width={4}>
                    <Image as="a" href="https://www.coingecko.com/en/coins/horizon-dollar" size="small" src={coingecko_logo} style={{ backgroundColor: "#fff" }} />
                </Grid.Column>
                <Grid.Column width={4} style={{ color: "#2B468B" }}>
                    <CopyToClipboard text="0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347" onCopy={() => HandleOnCopy()}>
                        <Button basic icon labelPosition="right" style={{ wordSpacing: "0.3em", }}>
                            {copyPlaceholderText}
                            <Icon name="copy outline" />
                        </Button>
                    </CopyToClipboard>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default Partners;
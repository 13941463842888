import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import newhzdlogo from "../assets/images/hzd1.svg";
import "../App.css";


const tradetokenStyle ={
    border: "2px solid #800080", 
    color: "#800080",
    borderRadius: "8px",
    marginLeft: "5px"
}


const ResponsiveBoostrapNavBar = () => (
    <Navbar bg="dark" variant="light" expand="lg" collapseOnSelect 
        className="shadow-sm p-3 mb-5 bg-white rounded" fixed="top"
        style={{fontFamily: "montserrat", }} 
    >
        <Navbar.Brand href="/#home" className="mr-auto" >
            <Image  src={newhzdlogo} style={{ width: "45px", fontWeight: "bold"}} /> Horizon Dollar Token
        </Navbar.Brand>
        

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" style={{  fontSize: "16px", lineHeight: "21px", fontWeight: "500", }}>
            <Nav style={{ textAlign: "center",  color: "#909090" }}  >
                <Nav.Link href="/#home" className="my-auto" style={{ color: "#909090"}}>Home</Nav.Link>
                <Nav.Link href="/#tokenomics" className="my-auto" style={{ color: "#909090"}}>Tokenomics</Nav.Link>
                <Nav.Link href="/#howitworks" className="my-auto" style={{ color: "#909090"}}>How it works</Nav.Link>
                <Nav.Link href="/#roadmap" className="my-auto" style={{ color: "#909090"}}>Roadmap</Nav.Link>
                <Nav.Link href="/tradetoken" className="my-auto" style={tradetokenStyle}>Buy Token</Nav.Link>

            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export { ResponsiveBoostrapNavBar };
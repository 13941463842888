import React, {useState} from 'react';
import { Segment, Grid, Header, Image, Button } from "semantic-ui-react";
import bscscan_logo from "../assets/images/bscscan-logo-light.svg";
import metamask_logo from "../assets/images/metamask.png";
import coinmarketcap_logo from "../assets/images/coinmarketcap.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "../App.css";


const TokenDetails = () => {

    const [copyPlaceholderText, setcopyPlaceholderText] = useState("Copy Contract Address");
    const [copyPlaceholderText2, setcopyPlaceholderText2] = useState("Copy Contract Address");

    const HandleOnCopy = () => {
        setcopyPlaceholderText("copied!");
        setTimeout(() => setcopyPlaceholderText("Copy Contract Address"), 1000)
    }
    const HandleOnCopy2 = () => {
        setcopyPlaceholderText2("copied!");
        setTimeout(() => setcopyPlaceholderText2("Copy Contract Address"), 1000)
    }

    return (
        <Segment basic id="tokenomics" style={{ backgroundColor: "#800080", padding: "20px 15px 120px", fontFamiy: "montserrat" }}>
            <Grid centered container stackable verticalAlign="middle" columns={3} >

                <Grid.Column textAlign="center" >
                    <Header as="h2" style={{color: "#fff", marginBottom: "60px"}}> HZD Token Address</Header>
                    <Header as="h4" style={{wordWrap: "break-word", fontFamiy: "montserrat", color: "#fff", fontSize: "24px", lineHeight: "24px", fontWeight: "400", }}>0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347</Header>
                    <CopyToClipboard style={{backgroundColor: "inherit", color:"#fff", margin: "40px 0",}}text="0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347" onCopy={() => HandleOnCopy()}>
                        <Button inverted size="big" style={{ wordSpacing: "0.3em", }}>
                            {copyPlaceholderText}
                        </Button>
                    </CopyToClipboard>
                     <Image  centered style={{display: "block"}} size="small" href="https://bscscan.com/token/0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347" src={bscscan_logo} />
                    
                </Grid.Column>

                <Grid.Column textAlign="center" >
                    <Header as="h2" style={{color: "#fff", marginBottom: "60px"}}> Add HZD token to metamask</Header>
                    <Header as="h4" style={{ visibility: "hidden", wordWrap: "break-word", fontFamiy: "montserrat", color: "#fff", fontSize: "24px", lineHeight: "24px", fontWeight: "400", }}>0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347</Header>

                    <CopyToClipboard style={{backgroundColor: "inherit", color:"#fff", margin: "40px 0"}} text="0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347" onCopy={() => HandleOnCopy2()}>
                        <Button inverted size="big" style={{ wordSpacing: "0.3em", }}>
                            {copyPlaceholderText2}
                        </Button>
                    </CopyToClipboard>
                     
                    <Image centered style={{display: "block"}} size="small" href="#" src={metamask_logo} />

                </Grid.Column>

                <Grid.Column textAlign="center" >
                    <Header as="h2" style={{color: "#fff", marginBottom: "60px"}}> Check HZD on coimarketcap </Header>
                    <Header as="h4" style={{ visibility: "hidden", wordWrap: "break-word",fontFamiy: "montserrat", color: "#fff", fontSize: "24px", lineHeight: "24px", fontWeight: "400", }}>0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347</Header>
                    
                        <Button inverted size="big" style={{ backgroundColor: "inherit", color:"#fff", margin: "40px 0", wordSpacing: "0.3em", }}>
                           Go TO SITE
                        </Button>
                     <Image  centered size="small" style={{display: "block"}} href="#" src={coinmarketcap_logo} />
                    
                </Grid.Column>

            </Grid>
        </Segment>
    );
}


export default TokenDetails;
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { Segment, Container, Icon, Header } from "semantic-ui-react";



const centerIcon = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}


const RoadMap = () => {
    return (
        <Segment style={{ backgroundColor: "#f1faee", marginBottom: "0", marginTop: "0" }} basic id="roadmap">
            <Container>
                <Header as="h2" style={{ textAlign: "center", margin: "10px 15px" }}>Road Map</Header>
                <VerticalTimeline style={{ backgroundColor: "grey" }}>

                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ borderTop: "2.5px solid #607D8B" }}
                        date="Stage 1"
                        iconStyle={{ background: '#607D8B', color: '#fff', ...centerIcon }}
                        icon={<Icon name="play" size="large" />}
                    >
                        <h4 className="vertical-timeline-element-subtitle">
                            HZD Contract created, website and social handles released and marketing starts immediately.
                        </h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ borderTop: "2.5px solid #0DB9B1" }}
                        date="Stage 2"
                        iconStyle={{ background: '#0DB9B1', color: '#fff', ...centerIcon }}
                        icon={<Icon name="block layout" size="large" />}
                    >
                        <h4 className="vertical-timeline-element-subtitle">
                        HZD presale contract created and IEO (initial token offering) on Probit Exchange.
                        </h4>

                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ borderTop: "2.5px solid #E19494" }}
                        date="Stage 3"
                        iconStyle={{ background: '#E19494', color: '#fff', ...centerIcon }}
                        icon={<Icon name="exchange" size="large" />}
                    >
                        <h4 className="vertical-timeline-element-subtitle">
                        HZD Listing on Probit exchange and coingecko listing follows.
                        </h4>

                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ borderTop: "2.5px solid #6E2048" }}
                        date="Stage 4"
                        iconStyle={{ background: '#6E2048', color: '#fff', ...centerIcon }}
                        icon={<Icon name="handshake" size="large" />}
                    >
                        <h4 className="vertical-timeline-element-subtitle">
                        Listing on Pancakeswap ( HZD/BNB).
                        </h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ borderTop: "2.5px solid #6E2048" }}
                        date="Stage 5"
                        iconStyle={{ background: '#6E2048', color: '#fff', ...centerIcon }}
                        icon={<Icon name="handshake" size="large" />}
                    >
                        <h4 className="vertical-timeline-element-subtitle">
                        Listing on more exchanges(names to be revealed) and more partneship with charity controls.
                        </h4>
                    </VerticalTimelineElement>

                </VerticalTimeline>
            </Container >
        </Segment>
    );
}

export default RoadMap;
import React from 'react';
import { Segment, Grid, Header, Button, Image } from "semantic-ui-react";
import HowItWorksbanner from "../assets/images/HowItWorks.jpg";



const  HowItWorks = () => {
    return (
        <Segment basic id="howitworks" style={{  fontFamiy: "montserrat" }}>
            <Grid centered container stackable verticalAlign="middle" columns={2}  divided relaxed>
                <Grid.Column width={8}>
                    <Image src={HowItWorksbanner} fluid/>
                </Grid.Column>
                <Grid.Column width={8} floated="left" >
                    <Header as="h2" 
                    style={{ fontFamily: "montserrat", fontWeight: "900", color: "#404040", fontSize: "2.5em"}}>
                    It's Simple!
                    </Header>
                    <Header as="h4" 
                    style={{ marginTop: "0", fontFamily: "montserrat", fontWeight: "500", color: "#404040", fontSize: "16px", lineHeight: '1.4', wordSpacing: "0.1em", fontFamiy: "montserrat" }}
                    >
                        On the assigned platforms for the freelance, one can use cryptocurrency to pay for a 
                        posted job and a freelancer can accept cryptocurrency for his done works. Regarding 
                        the charity a platform has been created solely for donations 
                        in cryptocurrency and all charity works will be 100% transparent 
                        
                    </Header>
                    <Button basic as="a" href="https://www.ramznetwork.com" style={{marginRight: "1.2em"}}>Go To Freelance</Button>
                    <Button basic as="a" href="https://www.hzdcharity.com"color="violet">Go To Charity</Button>
                </Grid.Column>
                    
            </Grid>
        </Segment>
    );
}

export default HowItWorks;
import React from 'react';
import TradeTokenHomePage from '../components/TradeTokenHomePage';
import Exchanges from "../components/Exchanges";



 const TradeToken = () => {
    return(
        <div style={{backgroundColor: "#F7F7F7", margin: "0", padding:"0"}}>
            <TradeTokenHomePage />
            <Exchanges />        
        </div>
    );
}

export default TradeToken;





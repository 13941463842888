import React from 'react';
import { Segment, Grid, Image, Header, Button, Icon } from "semantic-ui-react";
import logo1 from "../assets/images/hzd_icon-01.jpg"
import logo2 from "../assets/images/hzd.png"
import logo3 from "../assets/images/hzd1.svg"

const btnstyles = {
    color: "#909090", 
    marginBottom: "1em", 
    borderRadius: "10px",
};




const DownloadLogo = () => {
    return (
        <Segment basic style={{ marginTop: "0" }} id="downloadlogo">
            <Grid container centered verticalAlign="middle" stackable columns={3}>
                <Grid.Row>
                    <Header as="h2" style={{ paddingTop: "20px", letterSpacing: "0.10em", color: "#2F55D4", marginTop: "30px", marginBottom: "30px" }}><span style={{ border: "2px dashed #264653", padding: "10px", borderRadius: "3.5px" }}>Download Our Logos</span></Header>
                </Grid.Row>

                <Grid.Column>
                    <Segment raised textAlign="center">
                        <Image src={logo1} />
                      <Button inverted color="grey" style={btnstyles} as="a" download href={logo1}>
                        Download jpg format
                        <Icon name="download" style={{marginLeft: "0.5em"}}/>
                    </Button> 
                    </Segment>
                </Grid.Column>

                <Grid.Column>
                    <Segment raised textAlign="center">
                        <Image src={logo2} />
                        <Button inverted color="grey" style={{...btnstyles, marginTop: "2.6em"}} as="a" download href={logo2}>
                        Download png format
                        <Icon name="download" style={{marginLeft: "0.5em"}}/>
                    </Button> 
                    </Segment>
                </Grid.Column>

                <Grid.Column>
                    <Segment raised textAlign="center">
                        <Image src={logo3} />
                        <Button inverted color="grey" style={{...btnstyles, marginTop: "2.6em"}} as="a" download href={logo3}>
                        Download svg format
                        <Icon name="download" style={{marginLeft: "0.5em"}}/>
                    </Button> 
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default DownloadLogo;
import React from 'react';
import Home from "../components/Home";
import { Container } from 'semantic-ui-react';
import Partners from "../components/Partners";
import Tokenomics from "../components/Tokenomics";
import CoreValues from "../components/CoreValues";
import RoadMap from "../components/RoadMap";
import HowItWorks from '../components/HowItWorks';
import TokenDetails from "../components/TokenDetails";
import DownloadWhitePaperNew from '../components/DownloadWhitePaper_New';
import DownloadLogo from "../components/DownloadLogo"

const HomePage = () => {
    return (
        <React.Fragment>
            <Container>
                <Home />
                <Partners />
            </Container>
            <Tokenomics />
            <HowItWorks />
            <CoreValues />
            <TokenDetails />
            <RoadMap />
            <DownloadWhitePaperNew/>
            <DownloadLogo />
        </React.Fragment>

    );
}

export default HomePage;
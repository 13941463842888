import React from 'react';
import { Segment, Grid, Header, List, Image, Icon } from "semantic-ui-react";
import total_supply from "../assets/images/total_supply.svg";
import burning from "../assets/images/burning.svg";
import tax from "../assets/images/tax.svg";
import "../App.css";


const Tokenomics = () => {
    return (
        <Segment basic id="tokenomics" style={{ backgroundColor: "#FAFAFA", paddingBottom: "40px", fontFamiy: "montserrat" }}>
            <Grid centered id="tokenomics">
                <Grid.Column>
                    <Header textAlign="center" style={{ fontSize: "3.5em", letterSpacing: "0.1em", marginTop: "15px", fontFamiy: "montserrat" }}>Tokenomics
                    </Header>
                </Grid.Column>
            </Grid>
            <Grid centered container stackable verticalAlign="middle" columns={2} padded>
                <Grid.Column width={8}>
                    <Header as="h4" style={{ fontSize: "1.2em", fontWeight: "550", lineHeight: '1.4', wordSpacing: "0.1em", fontFamiy: "montserrat" }}>
                        HZD is a liquidity generation protocol. 
                        Upon each transaction, the protocol automatically distributes 
                        rewards to holders as well as auto-locks a portion of the fees as part of the liquidity pool.
                    </Header>
                </Grid.Column>
                <Grid.Column width={4}>
                    <List style={{ fontSize: "1.05em" }}>
                        <List.Item>
                            <Icon name="checkmark" />
                            <List.Content>
                                <List.Header style={{fontFamiy: "montserrat"}}>liquidity pool will be locked!
                                </List.Header>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Icon name="checkmark" />
                            <List.Content>
                                <List.Header>No creators share</List.Header>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <Icon name="checkmark" />
                            <List.Content>
                                <List.Header>Ownership renounced</List.Header>
                            </List.Content>
                        </List.Item>
                    </List>
                </Grid.Column>
            </Grid>
            <Grid centered container stackable verticalAlign="middle" columns={3}>
                <Grid.Column>
                    <Segment clearing raised color="red" style={{ maxWidth: "450px" }}>
                        <Header as="h4">Total Supply</Header>
                        <Header.Subheader style={{ fontSize: "1.035em", color: "#A3A5AE" }}>
                            5,000,000,000
                        </Header.Subheader>
                        <Image src={total_supply} floated="right" size="mini" style={{ marginTop: "0.6em" }} />
                    </Segment>
                </Grid.Column>

                <Grid.Column>
                    <Segment clearing raised color="teal" style={{ maxWidth: "450px" }}>
                        <Header as="h4">Burning</Header>
                        <Header.Subheader style={{ fontSize: "1.035em", color: "#A3A5AE" }}>
                            60% of the total supply will get burned.
                        </Header.Subheader>
                        <Image src={burning} floated="right" size="mini" style={{ marginTop: "0.6em" }} />
                    </Segment>
                </Grid.Column>
                {/* <Grid.Column>
                    <Segment clearing raised color="blue" style={{ maxWidth: "450px" }}>
                        <Header as="h4">Transaction tax</Header>
                        <Header.Subheader style={{ fontSize: "1.035em", color: "#A3A5AE" }}>
                            3% Liquidity pool and 3% distributed to holders.
                        </Header.Subheader>
                        <Image src={tax} floated="right" size="mini" style={{ marginTop: "0.6em" }} />
                    </Segment>
                </Grid.Column> */}
                <Grid.Column>
                    <Segment clearing raised color="blue" style={{ maxWidth: "450px" }}>
                        <Header as="h4">Max Supply</Header>
                        <Header.Subheader style={{ fontSize: "1.035em", color: "#A3A5AE" }}>
                            10,000,000,000
                        </Header.Subheader>
                        <Image src={tax} floated="right" size="mini" style={{ marginTop: "0.6em" }} />
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default Tokenomics;
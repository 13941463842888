import React from 'react';
import HomePage from "./pages/HomePage";
import TradeToken from "./pages/TradeToken";
import { ResponsiveBoostrapNavBar } from './components/BoostrapNavBar';
import Footer2 from './components/Footer2';
import {Route, BrowserRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-vertical-timeline-component/style.min.css';
import "./App.css"


const App = () => {
  return (
    <>
      <ResponsiveBoostrapNavBar />
      <Router>
        <Route path="/" exact component={HomePage}/>
        <Route path="/tradetoken" component={TradeToken}/>
      </Router>
      <Footer2 />
    </>
  );
}

export default App;
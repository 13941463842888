import React from 'react';
import { Segment, Grid, Header, Image, Button } from "semantic-ui-react";
import homepagebanner from "../assets/images/homepagebanner.svg";



const Home = () => {
    return (
        <Segment basic style={{ padding: '0em 0em', marginTop: "7em", fontFamily: "montserrat" }} id="home">
            <Grid relaxed stackable verticalAlign="middle" padded="vertically">
                <Grid.Column width={7}>
                    <Header as="h3" style={{ fontSize: '1.5em', color: "#A10638", fontFamily: "montserrat", marginBottom: "0.2em" }} >
                        Horizon Dollar Token
                    </Header>
                    <Header as="h1" style={{marginTop: "0", fontFamily: "montserrat", fontWeight: "900", color: "#404040"}}>
                       Blockchain integrated Charity and Freelance platform
                    </Header>
                    <p as="h1" style={{fontFamily: "montserrat", fontWeight: "500", lineHeight: "24px", color: "#404040", fontSize: "16px"}}>
                        Horizon Dollar ( HZD ) is a Blockchain project that is focused on 
                        increasing worth of charity by integrating it to Blockchain with 
                        the most transparent activities, it has also focused on bringing 
                        forth freelancing to the Blockchain by processing all payment modes 
                        using the Blockchain in partnership with  <a href="https://www.ramznetwork.com/">Ramz Network</a>                    
                    </p>
                    <div style={{ marginTop: "2em" }}>
                        <Button  basic as="a" size="big" href="#" color="standard" 
                        style={{ color: "white", marginRight: "0.7em", marginBottom: "1em", fontFamily: "montserrat", fontWeight: "500" }}>
                            Learn More
                        </Button>
                        <Button basic size="big" color="purple"  style={{fontFamily: "montserrat", fontWeight: "500"}} as="a" href="/tradetoken">Trade Now</Button>
                    </div>
                </Grid.Column>
                <Grid.Column width={9}>
                    <Image fluid src={homepagebanner} /> 
                </Grid.Column>
            </Grid>
        </Segment>
    );
}




export default Home;
import React from 'react';
import { Segment, Grid, Header, Image, Button, Container } from "semantic-ui-react";
import tradetokenbanner from "../assets/images/tradetokenbanner.png";


const TradeTokenHomePage = () => {
    return (
        <div style={{backgroundColor: "#F7F7F7"}}>
            <Container>
                <Segment basic style={{ padding: '8em 0em', fontFamily: "montserrat" }} id="home">
                    <Grid relaxed stackable verticalAlign="middle" padded="vertically">
                        <Grid.Column width={7}>
                            <Header as="h1" style={{marginTop: "0", fontFamily: "montserrat", fontWeight: "900", color: "#404040"}}>
                            Horizon Dollar Supported Exchanges
                            </Header>
                            <p as="h1" style={{fontFamily: "montserrat", fontWeight: "500", lineHeight: "24px", color: "#404040", fontSize: "16px"}}>
                                Horizon Dollar ( HZD ) is currently available on the exchanges listed below. 
                                The following exchanges are supported and personally recommended by HZD team. 
                                Add HZD Token address to MetaMask or other wallet to make sure your HZD tokens
                                are visible in the list of assets.                    
                            </p>
                            <div style={{ marginTop: "2em" }}>
                                <Button basic size="big" color="purple"  style={{fontFamily: "montserrat", fontWeight: "500"}} as="a" href="https://www.probit.com/app/exchange/HZD-USDT">Trade Now</Button>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Image fluid src={tradetokenbanner} /> 
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Container>
        </div>

    );
}

export default TradeTokenHomePage;


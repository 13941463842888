import React from 'react';
import { Segment, Grid, Header, Button, Image } from "semantic-ui-react";
import whitepaperbanner from "../assets/images/whitepaper4.png";
import "../App.css";
import { saveAs } from "file-saver";



const downloadbtnstyle = {marginTop: "20px", backgroundColor: "inherit", color:"#fff", fontWeight: "500"};

 const DownloadWhitePaperNew = () => {

    const saveFile = () => {
        saveAs(
            "https://privateemail.com/ajax/share/0ef15f1d0a94a340eec485ba94a3448a9b1bfa0004b31a30/1/8/Mzc/MzcvMQ",
            "whitepaper.pdf"
        );
      };

    return (
        <Segment basic id="howitworks" style={{  fontFamiy: "montserrat", backgroundColor: "#800080", marginTop: "0", marginBottom: "0", paddingBottom: "40px", paddingTop: "30px" }}>
            <Grid centered container stackable verticalAlign="middle" columns={2}  relaxed>
                
                <Grid.Column width={8} floated="left" >
                    <Header as="h2" 
                    style={{ fontFamily: "montserrat", fontWeight: "600", color: "#fff", fontSize: "28px", lineHeight: '36px'}}>
                    View White Paper to learn more.
                    </Header>

                   <Button size="huge" 
                    inverted 
                    onClick={saveFile} 
                    style={downloadbtnstyle}
                    >View WP</Button>

                </Grid.Column>

                <Grid.Column width={8}>
                    <Image src={whitepaperbanner} style={{backgroundColor: "#800080"}} fluid/>
                </Grid.Column>
                    
            </Grid>
        </Segment>
    );
}

export default DownloadWhitePaperNew;
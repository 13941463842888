import React from 'react';
import { Segment, Grid, Image, Header } from "semantic-ui-react";
import diversity from "../assets/images/diversity.jpg"
import donation from "../assets/images/donation.svg"
import give from "../assets/images/give.svg"



const CoreValues = () => {
    return (
        <Segment basic style={{ marginTop: "0" }}>
            <Grid container centered verticalAlign="middle" stackable columns={3}>
                <Grid.Row>
                    <Header as="h1" style={{ paddingTop: "20px", letterSpacing: "0.10em", color: "#2F55D4", marginTop: "30px", marginBottom: "30px" }}><span style={{ border: "2px dashed #264653", padding: "10px", borderRadius: "3.5px" }}>Core Values</span></Header>
                </Grid.Row>

                <Grid.Column>
                    <Segment raised >
                        <Image src={donation} size="large" />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment raised>
                        <Image src={diversity} />
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment raised>
                        <Image src={give} />
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default CoreValues;
import React from "react";
import {Segment, Grid, Header, Image,  Button} from "semantic-ui-react";
import probitlogo from "../assets/images/probit-logo.png"
import pancakeswaplogo from "../assets/images/pancakeswap-cake-logo.png"
import "../App.css";

const headerStyle = {
    fontFamily: "montserrat",
    fontSize: "700",
    color: "#404040"
}

const btnstyles = {color: "#909090", marginTop: "1em", borderRadius: "10px"};


const Exchanges = () => {
    return (
        <Segment basic style={{paddingBottom: "40px"}}>
            <Grid  centered container  stackable verticalAlign="middle"  style={{fontFamily: "montserrat", backgroundColor: "#F7F7F7"}} columns={2}>
                <Grid.Column >
                    <Segment clearing raised textAlign="center" style={{ maxWidth: "450px" }} padded="very">
                        <Image centered src={probitlogo}  size="tiny" style={{ marginTop: "2em" }} />
                        <Header as="h2" style={headerStyle}>Probit</Header>
                        <Header.Subheader style={{ fontSize: "17px", color: "#A3A5AE", margin: "10px" }}>
                        Listed
                        </Header.Subheader>
                        <Header.Subheader style={{ fontSize: "14px", color: "#A3A5AE", marginBottom: "12px" }}>
                        Centralized  <br /> Top Tier exchange
                        </Header.Subheader>
                        
                        
                        <Button inverted color="grey"  size="big" style={btnstyles} as="a" href="https://www.probit.com/app/exchange/HZD-USDT">TRADE NOW</Button>
                    </Segment>
                </Grid.Column>

                <Grid.Column >
                    <Segment clearing raised  textAlign="center"  style={{ maxWidth: "450px" }}  padded="very">
                        <Image centered src={pancakeswaplogo}  size="tiny" style={{ marginTop: "2em" }} />
                        <Header as="h2" style={headerStyle}>Pancakeswap</Header>
                        <Header.Subheader style={{ fontSize: "17px", color: "#A3A5AE", marginBottom: "10px" }}>
                            Listed
                        </Header.Subheader>
                        <Header.Subheader style={{ fontSize: "14px", color: "#A3A5AE", marginBottom: "12px" }}>
                        Decentralized exchange liquidity added by the community
                        </Header.Subheader>

                        <Button inverted color="grey"  size="big" style={btnstyles} as="a" href="https://pancakeswap.finance/swap?inputCurrency=0xfefbbeebbeef18e3e4a2909f2b2729ca2fc61347">TRADE NOW</Button>

                    </Segment>
                </Grid.Column>

        </Grid>
    </Segment>
    );
}

export default Exchanges;